.witdraw-royalty-page {
  color: var(--white);
  h1 {
    color: var(--white);
    font-weight: bold;
  }

  .amount {
    margin-left: 15px;
  }

  .btn-withdraw {
    margin-top: 20px;
  }

  .balance {
    font-size: 20px;
    font-weight: 600;
  }

  .amount-form {
    margin-top: 20px;
  }
}
