.badge-management {
  button {
      background: var(--colorLinearGradient);
      padding: 12px 40px;
      font-weight: 700;
      font-size: var(--fontMed);
      color: var(--white);
      border: none;
      border-radius: 999px;
      cursor: pointer;
      display: flex;
      align-items: center;
  }
}
