.search-bar {
    width: 268px;
    padding: 12px 24px;
    background: #1b1b1d;
    border: 1px solid #404040;
    border-radius: 4px;
    display: flex;
    align-items: center;

    &__input {
        border: none;
        background: transparent;
        outline: none;
        flex-grow: 1;
        padding-right: 5px;

        &::placeholder {
            font-weight: 600;
            font-size: var(--fontMed);
        }
    }

    &__btn-clear {
        color: #404040;
        margin-right: 5px;
        cursor: pointer;
    }

    &__btn-search {
        background: transparent;
        padding: 0;
        border: none;
        cursor: pointer;
    }
}
