.custom-status {
    display: inline-block;
    padding: 7.5px 24px;
    font-size: var(--fontSmall);
    font-weight: 700;
    border-radius: 999px;

    &.text-only {
        background: transparent !important;
        font-weight: 400;
        font-size: var(--fontMed);
        padding: 0;
    }

    &.editable {
        position: relative;
        z-index: 1;
        cursor: pointer;
    }

    img {
        width: 15px;
        margin-left: 2px;
    }
}

.ant-dropdown-menu .ant-dropdown-menu-item .ant-dropdown-menu-title-content {
    color: var(--white);
    font-weight: 600;
}
