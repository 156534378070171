.table-container {
    background: #121212;
    border: 1px solid #404040;
    border-radius: 8px;

    table {
        width: 100%;

        thead {
            tr {
                background: #212127;
                border-bottom: 1px solid #404040;
                border-top-right-radius: 8px;
            }

            th {
                text-align: left;
                padding: 12px 10px;
                font-size: 16px;
                
                @media screen and (min-width: 768px) {
                    padding: 12px 32px;
                }

                &:first-child {
                    border-top-left-radius: 8px;
                }

                &:last-child {
                    border-top-right-radius: 8px;
                }
            }
        }

        tbody {
            td {
                padding: 12px 10px;
                font-size: 16px;
                
                @media screen and (min-width: 768px) {
                    padding: 12px 32px;
                }
            }
        }

        .col-sm {
            width: 10%;
        }

        .col-lg {
            width: 45%;
        }
    }
}
