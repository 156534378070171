.creator-details {
    &__tbl-infos-wrapper {
        border-radius: 8px;
        overflow: hidden;
        border: 1px solid #404040;
        margin-bottom: 24px;

        table {
            width: 100%;
            table-layout: fixed;
            background: var(--dark);

            tr {
                border-bottom: 1px solid #212127;

                &:last-child {
                    border-bottom: none;
                }

                td {
                    padding: 12px 24px;
                    font-size: var(--fontMed);
                    overflow: hidden;
                    text-overflow: ellipsis;

                    &:first-child {
                        color: var(--white);
                        font-weight: 600;
                        width: 25%;
                    }

                    &:last-child {
                        color: var(--gray);
                        width: 75%;
                    }
                }
            }
        }
    }

    .copy-icon {
        cursor: pointer;
    }

    .btn-delete {
        margin-top: 20px;
    }

    &__chart {
        margin-bottom: 24px;
    }
}
