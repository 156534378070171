.crop-image-modal {
    width: 50% !important;

    .ant-modal-body {
        padding: 0px;
        background: #212127;
    }

    .ant-modal-content {
        border-radius: 15px;

        .ant-modal-close-icon {
            color: #fff;
            font-size: 28px;
            position: relative;
            top: 5px;
            right: 5px;
        }
    }

    .ant-modal-header {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        border: none;

        .ant-modal-title {
            visibility: hidden;
        }
    }

    .ant-modal-footer {
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        border: none;
    }

    .ant-modal-header, .ant-modal-footer {
        background: var(--dark)
    }

    .modal-footer {
        display: flex;
        justify-content: space-between;

        .footer-right {
            display: flex;
            column-gap: 10px;
        }
    
        .btn-submit {
            display: flex;
            justify-content: center;
            align-items: center;
            background: var(--colorLinearGradient);
            color: #fff;
            text-transform: uppercase;
            width: 140px;
            height: 48px;
            border: none;
            border-radius: 8px;
            font-size: 16px;
            font-weight: bold;
            cursor: pointer;
        }
    
        .btn-default {
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgba(255, 130, 0, 0.15);
            color: #fff;
            width: 140px;
            height: 48px;
            border: none;
            border-radius: 8px;
            font-size: 16px;
            font-weight: bold;
            cursor: pointer;

            &:disabled {
                opacity: 0.38;
                cursor: not-allowed;
            }
        }
    }

    .slide-container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 25px;
    
        .slide-item {
            width: 40%;

            .ant-slider-disabled {
                opacity: 0.38;
            }
    
            .slide-label {
                text-align:left;
                color: #fff;
                font-size: 16px;
                font-weight: 600;
            }
    
            .ant-slider-rail {
                background: var(--gray);
                height: 21px;
                border-radius: 10px;
            }
    
            .ant-slider-track {
                background: var(--colorLinearGradient);
                height: 21px;
                border-radius: 10px;
            }
    
            .ant-slider-step {
                height: 21px;
            }
    
            .ant-slider-handle {
                width: 31px;
                height: 31px;
                background: var(--colorLinearGradient);
                border: 2px solid #fff;
                box-shadow: 0px 0px 8px var(--colorLinearGradient);
            }
    
            .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
                border-color: #fff;
            }
        }
    
        .slide-item-reset {
            display: flex;
    
            .reset-label {
                color: #fff;
                font-size: 16px;
                font-weight: 600;
                margin-right: 10px;
            }
        }
    }

    .cropper-avatar {
        .cropper-crop-box, .cropper-view-box {
            border-radius: 50%;
        }
        
        .cropper-view-box {
            box-shadow: 0 0 0 1px #39f;
            outline: 0;
        }
        
        .cropper-face {
            background-color: inherit !important;
        }
        
        .cropper-dashed, .cropper-point.point-se, .cropper-point.point-sw, .cropper-point.point-nw,   .cropper-point.point-ne, .cropper-line {
            display: none !important;
        }
        
        .cropper-view-box {
            outline: inherit !important;
        }
    }
}
