.ant-space-item {
    cursor: pointer;
    margin-left: 3px;

    img {
        width: 13px;
        margin-bottom: 1px;
    }
}

.ant-dropdown {
    .ant-dropdown-menu {
        padding: 0;
        border-radius: 8px;
        background: #212127;
        box-shadow: 0px 14px 16px rgba(0, 0, 0, 0.16);

        &-item {
            padding: 10px 20px;

            &:hover {
                background: #ffffff0d;
            }
        }

        &-title-content .ant-checkbox-wrapper {
            color: var(--white);
            font-weight: 600;

            .ant-checkbox .ant-checkbox-inner {
                background-color: transparent;
                border-color: var(--gray);
            }

            .ant-checkbox.ant-checkbox-checked {
                & + span {
                    color: var(--orange);
                }

                &::after {
                    border-color: var(--orange);
                }

                .ant-checkbox-inner {
                    background-color: transparent;
                    border-color: var(--orange);

                    &::after {
                        border-color: var(--orange);
                    }
                }
            }
        }
    }
}
