.action-button {
    padding: 12px 40px;
    background: var(--colorLinearGradient);
    color: var(--white);
    font-weight: 600;
    font-size: var(--fontMed);
    border-radius: 999px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:disabled {
        cursor: no-drop;
        opacity: 0.8;
    }

    .loading {
        width: 15px;
        height: 15px;
        border-color: var(--white);
        border-top-color: transparent;
        margin-left: 5px;
    }
}
