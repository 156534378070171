.ant-input {
  padding: 12px 16px !important;
  border-radius: 4px !important;
  background-color: var(--dark) !important;
  font-size: var(--fontMed) !important;
  border: 1px solid #404040 !important;
  margin-bottom: 2px !important;
  outline: none !important;
  color: inherit !important;
}
