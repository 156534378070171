.doughnut-chart-container {
    width: 100%;
    height: 240px;
    background: #121212;
    border: 1px solid #404040;
    border-radius: 8px;
    padding: 16px;
    position: relative;

    .chart-title {
        position: absolute;
        top: calc((100% - 52px) / 2);
        left: calc((100% - 232px) / 2);
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
    }

    .no-data-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
    }
}
