.tabs {
    display: flex;
    padding: 6px;
    background: var(--lightOrange);
    border-radius: 999px;
    width: fit-content;

    &__item {
        font-size: var(--fontSmall);
        font-weight: 700;
        padding: 7.5px 32px;
        border-radius: 999px;
        cursor: pointer;
        text-transform: capitalize;
        background: transparent;
        border: none;

        &:disabled {
            cursor: no-drop;
        }

        &.active {
            background: var(--colorLinearGradient);
        }
    }
}
