.nft-details-container {
  .mb-20 {
    margin-bottom: 20px;
  }

  .mt-24 {
    margin-top: 24px;
  }

  .h-252 {
    height: 252px;
  }

  .color-white {
    color: var(--white);
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btn-back {
      font-size: 20px;
      flex-shrink: 0;
      color: #404040;
      margin-right: 20px;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        color: var(--white);
      }
    }

    h3 {
      font-size: var(--fontXLarge);
      color: var(--white);
      font-weight: 700;
      margin: 0;
    }

    .status {
      padding: 7.5px 24px;
      border-radius: 50px;
      font-size: 14px;
      line-height: 150%;
      width: fit-content;
      font-weight: 700;

      &__error {
        color: var(--red);
        border: 1px solid var(--red);
      }

      &__success {
        color: var(--green);
        border: 1px solid var(--green);
      }
    }
  }

  &__body {
    margin-top: 40px;

    &-info {
      display: flex;
      flex-direction: row;
    }

    .chart-container {
      margin-top: 40px;
    }

    .left {
      width: 40%;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      .background-photo {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }

      .image-container {
        width: 100%;
        position: relative;

        .gradient-fill {
          inset: 0;
          position: absolute;
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(22, 22, 26, 0.7) 95%
          );

          > div {
            width: 25px;
            height: 25px;
            margin: 5px;
            background-size: auto;
            background-repeat: no-repeat;
            background-size: contain;
          }
        }
      }

      .audio-controller {
        width: calc(100% - 20px);
        position: absolute;
        bottom: 10px;
        left: 10px;
      }

      audio::-webkit-media-controls-panel {
        background: transparent !important;
      }

      .icon-music {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: absolute;
        width: 25px;
        height: 25px;
        border: 1px solid var(--white);
        border-radius: 8px;
        margin-top: 10px;
        margin-left: 10px;
      }

      .tag-container {
        display: flex;
        flex-wrap: wrap;
        margin: 20px -7.5px 0;

        .tag-item {
          max-width: 33.33%;
          padding: 8.5px;

          > span {
            background: var(--gray);
            border-radius: 50px;
            padding: 7.5px 16px;
            font-size: 14px;
            line-height: 150%;
            font-weight: 700;
            color: var(--neutral4);
            height: fit-content;

            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            display: block;
          }
        }
      }
    }

    .right {
      padding-left: 64px;
      width: 60%;

      .nft-name {
        font-size: 24px;
        line-height: 150%;
        font-weight: 700;
        color: var(--white);
        word-wrap: break-word;
        max-width: 100%;

      }

      .price-line {
        margin-top: 12px;
        color: var(--gray);
        font-size: 14px;
        line-height: 21px;

        span {
          font-weight: 700;
        }

        .dot-character {
          margin: 0 4px;
        }

        .price {
          background: var(--colorLinearGradient);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      .desc {
        margin-top: 24px;
        color: var(--gray);
        word-wrap: break-word;

        .read-more {
          cursor: pointer;
          font-weight: 700;
        }
      }

      .block-info {
        display: grid;
        grid-template-columns: auto auto auto;

        .label {
          font-weight: 600;
          color: var(--gray);
          font-size: 12px;
          line-height: 18px;
        }

        .value {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 12px;

          > img {
            border-radius: 5px;
          }

          > div {
            font-weight: bold;
            margin-left: 16px;
            font-size: 14px;
            line-height: 21px;
            max-width: 120px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }

      .btn-container {
        font-size: 16px;
        line-height: 150%;
        margin-top: 40px;

        .btn-hide {
          background: var(--colorLinearGradient);
          padding: 12px 40px;
          color: var(--white);
          border-radius: 50px;
          font-weight: 700;
          border: none;
          cursor: pointer;
        }

        .btn-burn {
          margin-left: 10px;
          background: none;
          border-radius: 50px;
          padding: 12px 40px;
          border: 1px solid var(--orange);
          font-weight: 700;
          color: var(--orange);
          cursor: pointer;
        }
      }
    }

    .chart-name {
      margin-bottom: 16px;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
    }
  }
}
