.custom-table {
    border-radius: 8px;
    overflow-x: auto;
    font-size: var(--fontMed);
    border: 1px solid #404040;
    position: relative;

    &::-webkit-scrollbar {
        height: 6px;
    }
    
    &::-webkit-scrollbar-track {
        border-radius: 10px;
    }
    
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #464646; 
    }

    &__table {
        width: 100%;
        overflow-x: scroll;
        table-layout: fixed;
    }

    &__header {
        font-weight: 600;
        text-align: left;
        padding: 12px 24px;
        background: #1b1b1d;
        white-space: nowrap;

        .btn-filter {
            border: none;
            background: transparent;
            padding: 2px;
            margin-left: 2px;
            height: fit-content;
            cursor: pointer;

            &:hover {
                background: #121212;
            }

            img {
                width: 15px;
            }
        }
    }

    &__rows {
        position: relative;

        &:nth-child(2n + 1) {
            background: var(--dark);
        }

        &:nth-child(2n) {
            background: #1b1b1d;
        }

        .tr-mask {
            position: absolute;
            inset: 0;

            &:hover {
                cursor: pointer;
                background: var(--colorLightLinearGradient);
            }
        }

        &.no-data td {
            padding: 15rem 0;
            text-align: center;
            font-size: var(--fontMed);
            font-weight: 600;
        }
    }

    &__cells {
        padding: 8px 24px;
        height: 53px;
        color: var(--gray);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        .tooltip {
            position: relative;
            z-index: 1;
        }
    }

    &__loading-wrapper {
        position: absolute;
        inset: 0;
        background: var(--dark);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
    }
}
