.nft-management {
    &__rows {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 40px;

        .right-content {
            display: flex;
            align-items: center;
            column-gap: 14px;

            .btn-create-wrapper {
                width: 133px;
                height: 48px;
                padding: 1px;
                background: var(--colorLinearGradient);
                border-radius: 50px;

                .btn-create {
                    width: 100%;
                    height: 100%;
                    color: #fff;
                    display: flex;
                    column-gap: 13px;
                    justify-content: center;
                    align-items: center;
                    background: #121212;
                    border-radius: 50px;
                    font-size: 14px;
                    font-weight: bold;
                    cursor: pointer;
                }
            }
        }
    }

    &__pagination {
        margin-top: 28px;
    }
}
