.bar-chart-container {
    width: 100%;
    height: 260px;
    background: #121212;
    border: 1px solid #404040;
    border-radius: 8px;
    padding: 16px;
    position: relative;

    .no-data {
        font-weight: 600;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
