.sidebar {
    min-height: calc(100vh - 70px);
    background: var(--dark);
    font-weight: normal;
    padding: 0;
    width: 300px;
    z-index: 11;
    transition: width 0.25s ease, background 0.25s ease;
    -webkit-transition: width 0.25s ease, background 0.25s ease;

    .sidebar-brand-wrapper {
        width: 300px;
        height: 70px;
        display: flex;
        align-items: center;
        transition: width 0.25s ease, background 0.25s ease;
        -webkit-transition: width 0.25s ease, background 0.25s ease;

        .sidebar-brand {
            padding-left: 22px;
            color: #fff;
            font-size: 24px;
            text-transform: uppercase;
            overflow: hidden;
            width: 100%;
            transition: width 0.4s ease;

            img {
                width: 210px;
                margin-bottom: 5px;
            }

            &.collapsed {
                width: 60px;
            }
        }
    }

    .fixed-top {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1030;
        background: var(--dark);
    }

    .nav {
        overflow: hidden;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        margin-bottom: 60px;
        padding-left: 0;
        padding-top: 70px;
        list-style: none;

        .nav-item {
            padding-right: 1rem;
            transition-duration: 0.25s;
            transition-property: background;
            -webkit-transition-property: background;
            margin-top: 0.5rem;

            // &:hover:not(.active) {
            //     .nav-link {
            //         background: linear-gradient(
            //             99.51deg,
            //             rgba(255, 62, 62, 0.2) 8.87%,
            //             rgba(255, 130, 0, 0.2) 94.14%
            //         );
            //         position: relative;

            //         &::before {
            //             content: '';
            //             width: 4px;
            //             height: 100%;
            //             background: linear-gradient(
            //                 99.51deg,
            //                 #ff3e3e 8.87%,
            //                 #ff8200 94.14%
            //             );
            //             display: inline-block;
            //             position: absolute;
            //             left: 0;
            //             top: 0;
            //         }

            //         .menu-title {
            //             font-weight: 600;
            //             background: linear-gradient(
            //                 99.51deg,
            //                 #ff3e3e 8.87%,
            //                 #ff8200 94.14%
            //             );
            //             background-clip: text;
            //             -webkit-background-clip: text;
            //             -webkit-text-fill-color: transparent;
            //         }
            //     }
            // }

            .nav-link {
                display: flex;
                align-items: center;
                white-space: nowrap;
                padding: 0.8rem 10px 0.8rem 1.188rem;
                color: var(--gray);
                font-size: var(--fontMed);
                transition-duration: 0.45s;
                transition-property: color;
                -webkit-transition-property: color;
                height: 46px;
                border-radius: 0px 100px 100px 0px;
                cursor: pointer;

                .menu-icon {
                    margin-right: 0.5rem;
                    font-size: 0.8125rem;
                    line-height: 1;
                    width: 31px;
                    height: 31px;
                    border-radius: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #ffffff;
                }

                .menu-title {
                    color: inherit;
                    display: inline-block;
                    font-size: 0.9375rem;
                    line-height: 1;
                    vertical-align: middle;
                    font-size: var(--fontMed);
                }

                .menu-arrow {
                    margin-left: auto;
                    color: rgba(108, 114, 147, 1);
                    font-size: 14px;
                }
            }

            .collapsed-menu {
                z-index: 999;
                transition: height 0.35s ease;

                &:not(.show) {
                    display: none;
                }

                .sub-menu {
                    margin-bottom: 0;
                    margin-top: 0;
                    padding: 0 0 0 3.25rem;
                    list-style: none;
                    overflow: hidden;
                    flex-wrap: nowrap;
                    display: flex;
                    flex-direction: column;

                    .nav-item {
                        padding: 0;

                        &:hover {
                            .nav-link-sub {
                                background: transparent;
                            }
                        }

                        .nav-link-sub {
                            color: #6c7293;
                            padding: 0.5rem 0.35rem;
                            position: relative;
                            font-size: 0.855rem;
                            line-height: 1;
                            height: auto;
                            border-top: 0;
                            display: flex;
                            align-items: center;
                            white-space: nowrap;
                            transition-duration: 0.45s;
                            transition-property: color;
                            -webkit-transition-property: color;
                            border-radius: 0px 100px 100px 0px;
                            cursor: pointer;

                            &:hover {
                                color: #fff;
                            }
                        }

                        .active {
                            color: #fff;
                            background: transparent;
                        }
                    }
                }
            }
        }

        .active {
            .nav-link {
                background: var(--colorLightLinearGradient);
                position: relative;

                &::before {
                    content: '';
                    width: 4px;
                    height: 100%;
                    background: var(--colorLinearGradient);
                    display: inline-block;
                    position: absolute;
                    left: 0;
                    top: 0;
                }

                .menu-title {
                    font-weight: 600;
                    background: var(--colorLinearGradient);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

                .menu-icon {
                    background: var(--colorLightLinearGradient);

                    &.stroke {
                        path {
                            stroke: var(--red);
                        }
                    }

                    &.fill {
                        path {
                            fill: var(--red);
                        }
                    }
                }
            }
        }
    }
}

.sidebar-collapsed {
    width: 70px;

    .sidebar-brand-wrapper {
        width: 70px;
    }

    .nav {
        overflow: visible;

        .nav-item {
            position: relative;
            padding: 0;

            &:hover {
                .nav-link {
                    .menu-title {
                        display: flex;
                        align-items: center;
                        position: absolute;
                        top: 0px;
                        left: 70px;
                        bottom: 0px;
                        padding: 0.5rem 1.4rem;
                        background: #0f1015;
                    }
                }

                .collapsed-menu {
                    display: block;
                    padding: 0.5rem 0;
                    border-radius: 0 0 5px 0;
                    background: #0f1015;
                    position: absolute;
                    left: 70px;
                    width: 190px;

                    .sub-menu {
                        padding: 0 0 0 1.5rem;
                        overflow: visible;

                        .nav-item {
                            position: relative;
                            padding: 0;

                            .nav-link-sub {
                                text-align: left;
                                padding-left: 20px;

                                &::before {
                                    content: '';
                                    display: inline-block;
                                    width: 5px;
                                    height: 5px;
                                    border-radius: 50%;
                                    background-color: #6c7293;
                                    position: absolute;
                                    left: 0;
                                }
                            }
                        }
                    }
                }
            }

            .nav-link {
                text-align: center;

                .menu-title {
                    display: none;
                }

                .menu-arrow {
                    display: none;
                }
            }

            .collapsed-menu {
                display: none;
            }
        }

        .active {
            &:hover {
                .nav-link {
                    .menu-title {
                        span {
                            background: var(--colorLinearGradient);
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .sidebar-offcanvas {
        position: fixed;
        max-height: calc(100vh - 70px);
        top: 70px;
        bottom: 0;
        overflow: auto;
        right: -300px;
        transition: all 0.25s ease-out;

        .nav {
            padding-top: 0;
        }
    }

    .sidebar-active {
        right: 0;
    }
}
