.ant-pagination {
    text-align: center;
    font-size: var(--fontSmall) !important;

    &-item {
        background: #404040 !important;
        border-radius: 2px !important;
        padding: 1px;
        border: none !important;

        a {
            display: block !important;
            width: 100%;
            height: 100%;
            background: var(--dark);
            color: var(--white) !important;
        }

        &:hover a {
            color: auto;
        }
    }

    &-item-active {
        background: var(--colorLinearGradient) !important;

        a {
            color: var(--red) !important;
        }
    }

    &-item-ellipsis {
        border: 1px solid #404040;
        background: var(--dark);
        color: var(--white) !important;
    }

    &-prev,
    &-next {
        border: 1px solid #404040;

        &:hover .ant-pagination-item-link {
            color: var(--white);
        }

        .ant-pagination-item-link {
            background: var(--dark) !important;
            color: var(--white) !important;
            border: none !important;
        }
    }

    &-disabled .ant-pagination-item-link {
        color: #404040 !important;
    }
}
