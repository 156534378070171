
.orange-modal {
  .ant-modal-content {
    background: var(--colorOrangeLinearGradient);
    border-radius: 8px;
    border: 1px solid var(--orange);

    .ant-modal-body {
      padding: 32px;
    }

    .modal-content {
      margin-top: 24px;
    }
  
    .ant-modal-close {
      display: none;
    }
  }
}
