.create-collection {
    margin: -24px;

    &__form {
        background: var(--dark);
        padding: 32px;
        padding-right: 0;

        form {
            max-height: 600px;
            overflow-y: auto;

            &::-webkit-scrollbar {
                width: 6px;
            }
            
            &::-webkit-scrollbar-track {
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-color: #464646; 
            }
        }

        &-heading {
            font-weight: 700;
            font-size: var(--fontXLarge);
            color: var(--white);
            margin-bottom: 26px;
        }

        &-group {
            margin-bottom: 28px;
            padding-right: 32px;

            label {
                display: block;
                font-size: var(--fontMed);
                font-weight: 600;
                color: var(--white);

                span {
                    font-size: var(--fontXSmall);
                    font-weight: 400;
                    color: var(--gray);
                }

                .label-required {
                    margin-left: 5px;
                    color: var(--red);
                    font-size: var(--fontMed);
                }
            }

            #description {
                resize: none;
            }

            .form-control {
                width: 100%;
                padding: 12px 16px;
                border-radius: 4px;
                background: var(--dark);
                font-size: var(--fontMed);
                border: 1px solid #404040;
                margin-bottom: 2px;
                outline: none;
                color: var(--white);

                &::placeholder {
                    color: #404040;
                }
            }

            .input-prefix {
                position: relative;

                .form-control {
                    padding-left: 190px;
                }

                .prefix {
                    position: absolute;
                    top: 50%;
                    left: 16px;
                    transform: translateY(-55%);
                    display: flex;
                    align-items: center;
                    color: var(--white);
                    font-size: var(--fontMed);
                }
            }

            .hint {
                color: var(--gray);
                font-size: var(--fontXSmall);
            }

            .error-message {
                color: var(--red);
                font-size: var(--fontXSmall);
            }
        }

        &-actions {
            .btn-submit {
                background: var(--colorLinearGradient);
                padding: 12px 40px;
                font-weight: 700;
                font-size: var(--fontMed);
                color: var(--white);
                border: none;
                border-radius: 999px;
                cursor: pointer;
                display: flex;
                align-items: center;

                &:disabled {
                    opacity: 0.8;
                    cursor: no-drop;
                }

                .loading {
                    border-color: rgba($color: #ffffff, $alpha: 0.5);
                    border-top-color: #ffffff;
                    width: 20px;
                    height: 20px;
                }

                span {
                    margin-left: 5px;
                }
            }
        }

        input[type='file'] {
            display: none;
        }
    }

    &__preview-img {
        background: #1b1b1d;
        height: 100%;
        padding: 32px;

        img {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            object-fit: cover;
        }

        p {
            margin: 16px 0 25px;
            color: var(--white);
            font-size: var(--fontXSmall);
        }

        .btn-wrapper {
            background: var(--colorLinearGradient);
            border-radius: 999px;
            padding: 1px;
            width: fit-content;

            button {
                cursor: pointer;
                background: #1b1b1d;
                color: var(--white);
                font-size: var(--fontSmall);
                border-radius: 999px;
                border: none;
                padding: 5px 20px;
                font-weight: 700;
            }
        }
    }
}

.rounded-modal {
    border-radius: 8px;
}
