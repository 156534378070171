.page-container {
    min-height: 100vh !important;
    background: #000;
    color: #fff;
    overflow: hidden;
    display: flex;
    position: relative;

    .page-body-wrapper {
        min-height: calc(100vh - 70px);
        display: flex;
        flex-direction: row;
        padding-left: 0;
        padding-right: 0;
        width: calc(100% - 300px);
        transition: width 0.25s ease, margin 0.25s ease;
        margin-right: auto;
        margin-left: auto;

        .main-panel {
            transition: width 0.25s ease, margin 0.25s ease;
            width: 100%;
            min-height: calc(100vh - 70px);
            padding-top: 70px;
            display: flex;
            flex-direction: column;

            .top-toast-error {
                position: fixed;
                top: -118px;
                right: 0;
                z-index: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                width: calc(100% - 300px);
                height: 48px;
                background: linear-gradient(99.51deg, #330C0C 8.87%, #331A01 94.14%);
                transition: all ease 0.5s;
                font-size: 16px;
                font-weight: 600;
                color: var(--red);
                z-index: 1100;
        
                img {
                    width: 24px;
                    height: 24px;
                    margin-right: 8px;
                }
            }
        
            .toast-visible {
                top: 70px;
            }

            .content-wrapper {
                background: #000000;
                padding: 1.875rem 1.75rem;
                width: 100%;
                flex-grow: 1;
            }
        }
    }

    .page-collapsed {
        width: calc(100% - 70px);

        .main-panel {
            .top-toast-error {
                width: calc(100% - 70px);
            }
        }
    }
}


.eap-msg {
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    padding: 10px 50px;

    &.msg-error {
        background-color: #FF686B;
        color: #fff;
    }

    span, a {
        color: inherit;
        text-decoration: none;
        border-bottom: 2px solid #fff;
        cursor: pointer;
    }
}

@media screen and (max-width: 991px) {
    .page-container {
        .page-body-wrapper {
            width: 100%;
        }
    }
}
