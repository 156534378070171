.stat-card {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    width: 100%;
    min-height: 100px;
    border-radius: 8px;

    &__left {
        .card-data {
            font-weight: bold;
            font-size: 18px;
            margin-bottom: 4px;
        }

        .card-text {
            font-size: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }

    &__right {
        .icon-container {
            width: 32px;
            height: 32px;
            border-radius: 50%;
        }
    }
}

.card-default {
    background: #121212;
    border: 1px solid #404040;

    .stat-card__left {
        .card-data {
            background: linear-gradient(99.51deg, #FF3E3E 8.87%, #FF8200 94.14%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
        }

        .card-text {
            color: #ACACAC;
        }
    }
    
    // .stat-card__right {
    //     .icon-container {
    //         background: linear-gradient(99.51deg, #FF3E3E 8.87%, #FF8200 94.14%);
    //     }
    // }
}

.card-primary {
    background: linear-gradient(99.51deg, #FF3E3E 8.87%, #FF8200 94.14%);
    box-shadow: 4px 4px 16px rgba(255, 62, 62, 0.3);

    .stat-card__left {
        .card-data, .card-text {
            color: #fff;
        }

        .card-text {
            font-size: 14px;
        }
    }

    // .stat-card__right {
    //     .icon-container {
    //         background: #fff;
    //     }
    // }
}
