.details-header {
    margin-bottom: 45px;

    &__header-left {
        display: flex;
        align-items: center;

        .btn-back {
            width: 24px;
            height: 24px;
            cursor: pointer;
            color: #404040;
            transition: all 0.3s;
            flex-shrink: 0;

            &:hover {
                color: var(--white);
            }
        }

        .avatar {
            width: 48px;
            height: 48px;
            margin: 0 16px;
            border-radius: 999px;
        }

        .user-id {
            margin: 0;
            font-size: 18px;
            font-weight: 600;
        }

        .email {
            margin: 0;
            font-size: var(--fontSmall);
            color: var(--gray);
        }
    }

    &__header-right {
        display: flex;
        justify-content: flex-end;
    }
}
