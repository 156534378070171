.dashboard-page {
    &__custom-select {
        margin-bottom: 25px;

        select {
            width: 150px;
            height: 40px;
            background: #1B1B1D url('../../assets/icons/arrow-down.png') no-repeat calc(100% - 8px);
            border: 1px solid #404040;
            border-radius: 4px;
            padding: 8px 16px;
            outline: none;
            font-size: 16px;
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
        }
    }

    &__row {
        > div {
            margin-bottom: 24px;
        }
    }
}
