.confirm-modal {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      color: var(--red);
      text-transform: uppercase;
      font-size: var(--fontMed);
      font-weight: 700;
      margin: 0;
    }

    img {
      cursor: pointer;
    }
  }

  &__content {
    color: var(--white);
    font-size: var(--fontSmall);
    font-weight: 600;
  }

  &__btn-actions {
    display: flex;
    justify-content: center;
    gap: 10px;

    &.only-action button {
      flex: 1;
    }

    button {
      border: none;
      border-radius: 999px;
      width: 162px;
      color: var(--white);
      font-weight: 700;
      font-size: var(--fontMed);
      cursor: pointer;
      padding: 12px 0;

      &.btn-cancel {
        background: rgba(255, 130, 0, 0.15);
      }

      &.btn-confirm {
        background: var(--colorLinearGradient);
      }
    }
  }

  &__icon-loading {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
    transform: translate(-50%, -50%);
    margin: auto;
    border: 2px solid var(--red);
    border-top: 2px solid var(--dark);
  }

  @keyframes spinner {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .ant-modal-content {
    background: var(--colorOrangeLinearGradient);
    border-radius: 8px;
    border: 1px solid #FF8200;
  }

  .ant-modal-close {
    display: none;
  }

  .my-20 {
    margin: 20px 0;
  }

  .mt-24 {
    margin-top: 24px;
  }

  .text-center {
    text-align: center;
  }
}

.action-pending-modal {
  .ant-modal-body {
    padding: 40px;
  }
}
