.create-nft {
    &__header {
        display: flex;
        align-items: center;

        .btn-back {
            font-size: 20px;
            flex-shrink: 0;
            color: #404040;
            margin-right: 20px;
            cursor: pointer;
            transition: all 0.3s;

            &:hover {
                color: var(--white);
            }
        }

        h3 {
            font-size: var(--fontXLarge);
            color: var(--white);
            font-weight: 700;
            margin: 0;
        }
    }

    &__form {
        padding: 23px 0;

        &-group {
            margin-bottom: 32px;

            label {
                display: block;
                font-weight: 600;
                font-size: var(--fontMed);
                margin-bottom: 8px;

                span {
                    font-size: var(--fontXSmall);
                    color: var(--gray);
                    font-weight: 400;
                }

                .label-required {
                    margin-left: 5px;
                    color: var(--red);
                    font-size: var(--fontMed);
                }
            }

            &_label-collections {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .view-collection {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }

            textarea {
                resize: none;
            }

            .choose-file {
                background: var(--dark);
                border: 1px solid #404040;
                border-radius: 8px;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 24px;

                p {
                    color: var(--gray);
                    font-size: var(--fontSmall);
                    margin-bottom: 16px;
                }

                .btn-wrapper {
                    background: var(--colorLinearGradient);
                    border-radius: 999px;
                    padding: 1px;

                    button {
                        cursor: pointer;
                        background: var(--dark);
                        color: var(--white);
                        font-size: var(--fontSmall);
                        border-radius: 999px;
                        border: none;
                        padding: 5px 20px;
                        font-weight: 700;
                    }
                }

                + input {
                    display: none;
                }

                .file-preview {
                    display: flex;
                    justify-content: center;
                    position: relative;
                    width: 100%;

                    img,
                    video {
                        width: 40%;
                        border-radius: 8px;
                    }

                    audio {
                        width: 50%;
                    }

                    .btn-clear-file {
                        position: absolute;
                        top: 0;
                        right: 0;
                        cursor: pointer;
                        padding: 3px;
                        border: 1px solid #404040;
                        font-size: var(--fontXLarge);
                        border-radius: 4px;
                    }
                }
            }

            .inline {
                display: flex;
                justify-content: space-between;

                label {
                    font-weight: 700;
                    margin-bottom: 0;
                    background: var(--colorLinearGradient);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }

            .ant-switch {
                background: #404040;

                &-checked {
                    background: #10b981;

                    &:focus {
                        box-shadow: none;
                    }

                    .ant-switch-handle::before {
                        background-color: var(--white);
                    }
                }

                &-handle::before {
                    background-color: var(--gray);
                }
            }

            .hint {
                color: var(--gray);
                font-size: var(--fontSmall);

                &-small {
                    font-size: var(--fontXSmall);
                    color: var(--gray);
                }

                &-hightlight {
                    color: var(--orange);
                    font-weight: 600;
                }
            }

            .error-message {
                color: var(--red);
            }

            .warning-message {
                color: var(--orange);
            }

            .form-control {
                width: 100%;
                padding: 12px 16px;
                border-radius: 4px;
                background: var(--dark);
                font-size: var(--fontMed);
                border: 1px solid #404040;
                margin-bottom: 2px;
                outline: none;

                &::placeholder {
                    color: #404040;
                }
            }

            .input-suffix {
                position: relative;

                .form-control {
                    padding-right: 75px;
                }

                .suffix {
                    position: absolute;
                    top: 50%;
                    right: 16px;
                    transform: translateY(-60%);
                    display: flex;
                    align-items: center;

                    img {
                        margin-right: 10px;
                    }
                }
            }

            .collections {
                display: flex;
                flex-wrap: wrap;
                gap: 16px;

                &__item {
                    background: #404040;
                    padding: 1px;
                    width: 100px;
                    height: 100px;
                    border-radius: 4px;

                    .inner {
                        height: 100%;
                        padding: 10px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        background: var(--dark);
                        border-radius: 4px;

                        img {
                            width: 25px;
                            height: 25px;
                            border-radius: 50%;
                            margin-bottom: 8px;
                            object-fit: cover;
                        }

                        p {
                            font-size: var(--fontSmall);
                            color: var(--gray);
                            margin: 0;
                            width: 100%;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            text-align: center;
                        }
                    }

                    &--selected {
                        background: var(--colorLinearGradient);

                        .inner p {
                            font-weight: 700;
                            color: var(--white);
                        }
                    }
                }
            }

            .ant-select.ant-select-customize-input {
                width: 100%;
                outline: none;

                &:hover .ant-select-selector {
                    border-color: #404040;
                }

                &.multiple {
                    .ant-select-selector {
                        cursor: pointer;
                    }

                    .ant-select-selection-search-input {
                        caret-color: var(--dark);
                    }
                }

                &.single {
                    .ant-select-selector {
                        cursor: pointer;
                    }

                    .ant-select-selection-search {
                        width: 0;
                    }

                    .ant-select-selection-item {
                        color: var(--white);
                    }
                }

                .ant-select-selector {
                    padding: 12px 16px;
                    border-radius: 4px;
                    background-color: var(--dark);
                    font-size: var(--fontMed);
                    border: 1px solid #404040;
                    margin-bottom: 2px;
                }

                .ant-select-selection-placeholder {
                    color: var(--white);
                    left: 16px;
                }

                .ant-select-selection-overflow-item {
                    .ant-select-selection-item {
                        height: auto;
                        color: var(--green);
                        background: rgba(16, 185, 129, 0.2);
                        padding: 6px 16px;
                        border-radius: 999px;
                        font-size: var(--fontSmall);
                        border: none;

                        .ant-select-selection-item-content {
                            margin-right: 10px;
                        }
                    }

                    .ant-select-selection-item-remove {
                        color: var(--gray);
                    }
                }

                .ant-select-selection-search-input {
                    background-color: transparent;
                    border: none;
                    color: var(--white);
                    outline: none;
                    padding: 0;
                }
            }
        }

        &-actions {
            .btn-submit {
                background: var(--colorLinearGradient);
                padding: 12px 40px;
                font-weight: 700;
                font-size: var(--fontMed);
                color: var(--white);
                border: none;
                border-radius: 999px;
                cursor: pointer;
                display: flex;
                align-items: center;

                &:disabled {
                    opacity: 0.8;
                    cursor: no-drop;
                }

                .loading {
                    border-color: rgba($color: #ffffff, $alpha: 0.5);
                    border-top-color: #ffffff;
                    width: 20px;
                    height: 20px;
                }

                span {
                    margin-left: 5px;
                }
            }
        }
    }

    &__preview {
        padding-top: 23px;

        h4 {
            font-weight: 600;
            font-size: var(--fontMed);
            margin-bottom: 8px;
            color: var(--white);
        }

        &-file {
            background: var(--dark);
            border: 1px solid #404040;
            border-radius: 8px;
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            padding: 16px;

            > p {
                color: var(--gray);
                font-size: var(--fontSmall);
                text-align: center;
                padding: 150px 35px;
                margin: 0;
            }

            .file {
                align-self: flex-start;
                width: 100%;

                > img,
                > audio,
                > video {
                    width: 100%;
                    border-radius: 8px;
                    margin-bottom: 15px;
                }

                .music-icon {
                    width: auto;
                    position: absolute;
                    right: 30px;
                    border: 1px solid rgba(255, 255, 255, 0.5);
                }
            }

            .name {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;

                p {
                    margin: 0;
                    font-weight: 700;
                    font-size: var(--fontSmall);
                    max-width: 150px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                img {
                    width: 12.5px;
                }
            }

            .price {
                display: flex;
                justify-content: space-between;
                align-items: center;

                p {
                    margin: 0;
                    font-weight: 700;
                    font-size: var(--fontSmall);
                    background: var(--colorLinearGradient);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

                img {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                }
            }
        }

        &-digital-key {
            background: var(--dark) !important;
            border: 1px solid #404040;
            border-radius: 8px;
            padding: 16px;
            word-break: break-all;
            
            * {
                color: var(--gray) !important;
            }
        }
    }
}

.ant-select-dropdown.custom-dropdown {
    background-color: var(--dark);

    .ant-select-item-option {
        padding: 10px 14px;
        background-color: var(--dark);
        color: var(--gray);
        font-size: var(--fontMed);
        
        &-selected {
            background-color: #212127;
            color: var(--white);
        }

        &-state {
            display: none;
        }
    }

    .ant-empty-description {
        color: var(--white);
    }

    .rc-virtual-list-scrollbar-thumb {
        background-color: #464646 !important;
    }
}
