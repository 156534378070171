.market-place-container {
  .block-container {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
  }

  .block-item {
    background: var(--dark);
    border: 1px solid var(--neutral3);
    border-radius: 8px;
    padding: 16px 24px;
    width: 282px;
    min-width: 282px;
    height: 128px;
    margin: 32px 28px;

    .data {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .value {
        font-size: 18px;
        line-height: 150%;
        font-weight: 700;

        background: var(--colorLinearGradient);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .desc {
      margin-top: 4px;
      font-size: 14px;
      line-height: 150%;
      color: var(--gray);
      width: 70%;
    }
  }
}
