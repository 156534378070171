.navbar {
    position: fixed;
    top: 0;
    left: 300px;
    right: 0;
    z-index: 9;
    padding: 0;
    background: var(--dark);
    transition: left 0.25s ease;
    -webkit-transition: left 0.25s ease;
    display: flex;
    align-items: center;

    .navbar-brand-wrapper {
        display: none;
    }

    .navbar-menu-wrapper {
        display: flex;
        align-items: center;
        flex-grow: 1;
        color: #ffffff;
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
        height: 70px;
        transition: width 0.25s ease;
        -webkit-transition: width 0.25s ease;
        box-shadow: 20px 19px 34px -15px rgb(0 0 0 / 50%);
        -webkit-box-shadow: 20px 19px 34px -15px rgb(0 0 0 / 50%);

        .navbar-toggler {
            border: 0;
            width: 32px;
            height: 32px;
            border-radius: 999px;
            font-size: 14px;
            line-height: 1;
            background-color: transparent;
            cursor: pointer;
            background-color: #212127;
            color: var(--gray);
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .navbar-nav {
            display: flex;
            align-items: center;
            padding-left: 0;
            margin-bottom: 0;
            list-style: none;

            .nav-item {
                line-height: 1;
                position: relative;

                .navbar-profile {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    padding: 8px 0px;
                    margin: 0px 16px;

                    .profile-img {
                        width: 48px;
                        height: 48px;
                        border-radius: 50%;
                    }

                    .navbar-profile-name {
                        margin-left: 1rem;
                        white-space: nowrap;
                    }

                    .icon-caret-down {
                        color: #a7afb7;
                        margin-left: 5px;
                    }
                }

                .navbar-dropdown {
                    position: absolute;
                    inset: 0px 0px auto auto;
                    margin: 0px;
                    transform: translate(-16px, 61px);
                    font-size: 0.9rem;
                    box-shadow: 0px 0px 35px -3px #000;
                    padding: 0;
                    min-width: 160px;
                    z-index: 1000;
                    background: #191c24;
                    border-radius: 4px;

                    .navbar-dropdown-title {
                        padding: 16px;
                        font-weight: 500;
                        font-size: 16px;
                    }

                    .dropdown-divider {
                        margin: 0;
                        height: 0;
                        overflow: hidden;
                        border-top: 1px solid #2c2e33;
                    }

                    .dropdown-item {
                        display: flex;
                        align-items: center;
                        padding: 11px 13px;
                        margin-bottom: 0;
                        cursor: pointer;

                        &:hover {
                            background: #13151b;
                        }

                        .preview-thumbnail {
                            color: #ffffff;
                            position: relative;

                            .preview-icon {
                                width: 40px;
                                height: 40px;
                                text-align: center;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border-radius: 50%;
                                background: #0d0d0d;

                                .icon-signout {
                                    color: #FF5555;
                                }
                            }
                        }

                        .preview-item-content {
                            line-height: 1;
                            padding-left: 15px;
                        }
                    }
                }
            }
        }

        .navbar-nav-right {
            margin-left: auto;
        }

        .navbar-toogler-right {
            padding: initial;
            border-right: none;
            font-size: 20px;
            display: none;
        }
    }
}

.navbar-collapsed {
    left: 70px;
}

@media screen and (max-width: 991px) {
    .navbar {
        left: 0;
        .navbar-brand-wrapper {
            width: 75px;
            height: 70px;
            background: #191c24;
            display: flex;
            align-items: center;
            transition: width 0.25s ease, background 0.25s ease;
            -webkit-transition: width 0.25s ease, background 0.25s ease;

            .navbar-brand {
                color: #fff;
                font-size: 1.5rem;
                line-height: 48px;
                margin-right: 0;
                padding: 0rem 0rem 0rem 1.3rem;
                width: 100%;
                text-transform: uppercase;
            }
        }
        .navbar-menu-wrapper {
            .navbar-toogler-left {
                display: none;
            }
            .navbar-toogler-right {
                display: flex;
            }
        }
    }
}
