.not-found-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #0090e7;

    .not-found-container {
        display: flex;
        align-items: center;
        column-gap: 30px;

        .error-status {
            font-size: 12rem;
            color: #fff;
            margin-bottom: 0px;
        }

        .error-text {
            h2 {
                font-size: 1.875rem;
                font-weight: 500;
                text-transform: uppercase;
                color: #fff;
                margin-bottom: 0px;
            }

            h3 {
                font-size: 1.5rem;
                font-weight: 300;
                color: #fff;
                margin-bottom: 0px;
            }
        }
    }

    .home-redirect {
        margin-top: 3rem;
        
        a {
            color: #fff;
            font-size: 14px;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .not-found-page {
        .not-found-container {
            flex-direction: column;

            .error-text {
                text-align: center;
            }
        }
    }
}
