.create-badge {
  &__header {
    display: flex;
    align-items: center;

    .btn-back {
      font-size: 20px;
      flex-shrink: 0;
      color: #404040;
      margin-right: 20px;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        color: var(--white);
      }
    }

    h3 {
      font-size: var(--fontXLarge);
      color: var(--white);
      font-weight: 700;
      margin: 0;
    }
  }

  &__form {
    padding: 23px 0;

    &-group {
      margin-bottom: 32px;

      label {
        display: block;
        font-weight: 600;
        font-size: var(--fontMed);
        margin-bottom: 8px;

        span {
          font-size: var(--fontXSmall);
          color: var(--gray);
          font-weight: 400;
        }

        .label-required {
          margin-left: 5px;
          color: var(--red);
          font-size: var(--fontMed);
        }
      }

      textarea {
        resize: none;
      }

      .choose-file {
        background: var(--dark);
        border: 1px solid #404040;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 24px;

        p {
          color: var(--gray);
          font-size: var(--fontSmall);
          margin-bottom: 16px;
        }

        .btn-wrapper {
          background: var(--colorLinearGradient);
          border-radius: 999px;
          padding: 1px;

          button {
            cursor: pointer;
            background: var(--dark);
            color: var(--white);
            font-size: var(--fontSmall);
            border-radius: 999px;
            border: none;
            padding: 5px 20px;
            font-weight: 700;
          }
        }

        + input {
          display: none;
        }

        .file-preview {
          display: flex;
          justify-content: center;
          position: relative;
          width: 100%;

          img,
          video {
            width: 40%;
            border-radius: 8px;
          }

          audio {
            width: 50%;
          }

          .btn-clear-file {
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
            padding: 3px;
            border: 1px solid #404040;
            font-size: var(--fontXLarge);
            border-radius: 4px;
          }
        }
      }

      .inline {
        display: flex;
        justify-content: space-between;

        label {
          font-weight: 700;
          margin-bottom: 0;
          background: var(--colorLinearGradient);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      .ant-switch {
        background: #404040;

        &-checked {
          background: #10b981;

          &:focus {
            box-shadow: none;
          }

          .ant-switch-handle::before {
            background-color: var(--white);
          }
        }

        &-handle::before {
          background-color: var(--gray);
        }
      }

      .hint {
        color: var(--gray);
        font-size: var(--fontSmall);

        &-small {
          font-size: var(--fontXSmall);
          color: var(--gray);
        }

        &-hightlight {
          color: var(--orange);
          font-weight: 600;
        }
      }

      .error-message {
        color: var(--red);
      }

      .form-control {
        width: 100%;
        padding: 12px 16px;
        border-radius: 4px;
        background: var(--dark);
        font-size: var(--fontMed);
        border: 1px solid #404040;
        margin-bottom: 2px;
        outline: none;

        &::placeholder {
          color: #404040;
        }
      }

      .input-suffix {
        position: relative;

        .form-control {
          padding-right: 75px;
        }

        .suffix {
          position: absolute;
          top: 50%;
          right: 16px;
          transform: translateY(-60%);
          display: flex;
          align-items: center;

          img {
            margin-right: 10px;
          }
        }
      }
    }

    &-actions {
      .btn-submit {
        background: var(--colorLinearGradient);
        padding: 12px 40px;
        font-weight: 700;
        font-size: var(--fontMed);
        color: var(--white);
        border: none;
        border-radius: 999px;
        cursor: pointer;
        display: flex;
        align-items: center;

        &:disabled {
          opacity: 0.8;
          cursor: no-drop;
        }

        .loading {
          border-color: rgba($color: #ffffff, $alpha: 0.5);
          border-top-color: #ffffff;
          width: 20px;
          height: 20px;
        }

        span {
          margin-left: 5px;
        }
      }
    }
  }

  &__preview {
    padding-top: 23px;

    h4 {
      font-weight: 600;
      font-size: var(--fontMed);
      margin-bottom: 8px;
      color: var(--white);
    }

    &-file {
      background: var(--dark);
      border: 1px solid #404040;
      border-radius: 8px;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      padding: 16px;

      > p {
        color: var(--gray);
        font-size: var(--fontSmall);
        text-align: center;
        padding: 150px 35px;
        margin: 0;
      }

      .file {
        align-self: flex-start;
        width: 100%;

        > img,
        > audio,
        > video {
          width: 100%;
          border-radius: 8px;
          margin-bottom: 15px;
        }
      }

      .name {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        p {
          margin: 0;
          font-weight: 700;
          font-size: var(--fontSmall);
        }

        img {
          width: 12.5px;
        }
      }

      .price {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          margin: 0;
          font-weight: 700;
          font-size: var(--fontSmall);
          background: var(--colorLinearGradient);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        img {
          width: 32px;
          height: 32px;
          border-radius: 50%;
        }
      }
    }

    &-digital-key {
      background: var(--dark);
      border: 1px solid #404040;
      border-radius: 8px;
      height: 60px;
      padding: 16px;
    }
  }
}
