.loading {
    width: 35px;
    height: 35px;
    border: 2px solid var(--red);
    border-top: 2px solid var(--dark);
    animation: Spinner 1s linear infinite;
    border-radius: 100%;
}

@keyframes Spinner {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}