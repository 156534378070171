.create-creator-page {
    padding-top: 24px;
    
    .page-header {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 32px;

        .icon-back {
            width: 24px;
            height: 24px;
            cursor: pointer;
            color: #404040;
            transition: all 0.3s;
            margin-right: 21px;

            &:hover {
                color: var(--white);
            }
        }
    }

    .page-row {
        display: flex;
        column-gap: 64px;
        padding: 0px 45px;

        .col-left {
            width: 70%;

            .form-container {
                display: flex;
                flex-direction: column;
                row-gap: 18px;
                margin-bottom: 32px;

                .form-title {
                    font-size: 16px;
                    font-weight: 600;
                    color: #FF8200;
                    text-transform: uppercase;
                }

                .row-item {
                    display: flex;
                    column-gap: 16px;

                    .form-item {
                        flex: 1;

                        &__label {
                            margin-bottom: 2px;
                            padding-left: 8px;
                            font-size: 12px;
                            font-weight: 600;
                        }

                        .label-required {
                            margin-left: 5px;
                            color: var(--red);
                        }
        
                        &__input {
                            display: flex;
                            align-items: center;
                            position: relative;
                            box-sizing: border-box;
        
                            input, textarea, select, .date-picker {
                                width: 100%;
                                height: 48px;
                                font-size: 14px;
                                background: #1B1B1D;
                                border: none;
                                padding: 12px 16px;
                                border: 1px solid #404040;
                                border-radius: 4px;
                                color: #fff;
                                resize: none;
        
                                &:focus {
                                    outline: none;
                                }
                            }

                            input:disabled {
                                cursor: not-allowed;
                            }

                            textarea {
                                height: 80px;

                                &::-webkit-scrollbar {
                                    width: 3px;
                                }
                        
                                &::-webkit-scrollbar-track {
                                    border-radius: 12px;
                                }
                        
                                &::-webkit-scrollbar-thumb {
                                    border-radius: 12px;
                                    background-color: #464646;
                                }
                            }

                            select {
                                background: #1B1B1D url('../../../assets/icons/arrow-down.png') no-repeat calc(100% - 8px);
                                outline: none;
                                appearance: none;
                                -webkit-appearance: none;
                                -moz-appearance: none;
                            }

                            select:invalid {
                                color: #fff;
                                opacity: 0.38;
                            }

                            .date-picker {
                                padding-left: 45px;
                                cursor: pointer;
                                // pointer-events: none;
                                caret-color: var(--dark);

                                &::placeholder {
                                    color: #fff;
                                }
                            }

                            .prefix-icon {
                                position: absolute;
                                top: 13px;
                                left: 17px;
                                z-index: 1;
                                cursor: pointer;
                            }

                            .input-with-suffix {
                                padding-right: 50px;
                            }

                            .input-suffix {
                                position: absolute;
                                top: 13px;
                                right: 12px;
                                z-index: 1;
                                font-size: 16px;
                                opacity: 0.38;
                            }
                        }

                        &__error {
                            color: var(--red);
                            margin-top: 2px;
                            padding-left: 8px;
                            font-size: 12px;
                            font-weight: 400;
                        }
                    }

                    .form-half {
                        width:  calc(50% - 5px);
                        flex: unset;
                    }

                    .group-btn {
                        display: flex;
                        column-gap: 11px;
                        width:  calc(50% - 5px);
                        flex: unset;
                        margin-top: 14px;

                        .btn-submit, .btn-cancel {
                            flex: 1;
                            height: 48px;
                            border: none;
                            border-radius: 50px;
                            font-size: 16px;
                            font-weight: bold;
                            cursor: pointer;
                        }

                        .btn-submit {
                            background: var(--colorLinearGradient);
                        }

                        .btn-cancel {
                            background: rgba(255, 130, 0, 0.15);
                        }
                    }
                }
            }
        }

        .col-right {
            width: 30%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .col-title {
                font-size: 16px;
                font-weight: 600;
                text-transform: uppercase;
                color: #FF8200;
                margin-bottom: 24px;
            }

            .profile-item {
                width: 100%;
                display: flex;
                flex-direction: column;
                row-gap: 16px;
                align-items: center;
                margin-bottom: 32px;

                > div {
                    position: relative;

                    &:hover > .camera-overlay {
                        display: block;
                    }

                    .camera-overlay {
                        display: none;
                        position: absolute;
                        inset: 0;
                        background: #ffffff6e;

                        .icon-camera {
                            font-size: 40px;
                            color: #121212ba;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }
                }

                &__avatar {
                    width: 215px;
                    height: 215px;
                    border-radius: 50%;
                    background: #212127;
                    cursor: pointer;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                    }

                    .camera-overlay {
                        border-radius: 50%;
                    }
                }

                &__background {
                    width: 270px;
                    height: 128px;
                    border-radius: 8px;
                    background: #212127;
                    cursor: pointer;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 8px;
                    }

                    .camera-overlay {
                        border-radius: 8px;
                    }
                }

                &__label {
                    font-size: 12px;
                    font-weight: 600;
                    text-transform: uppercase;

                    span {
                        margin-left: 5px;
                        color: var(--red);
                    }
                }
            }
        }
    }
}

.react-datepicker-popper {
    .react-datepicker {
        font-family: 'Montserrat', sans-serif;
        background: #000000;
        color: #fff;
        border-color: #404040;

        .react-datepicker__navigation--previous, .react-datepicker__navigation--next {
            top: 10px;
        }

        .react-datepicker__month-container {
            .react-datepicker__header  {
                background: #1B1B1D;
                border-bottom-color: #404040;
                
                .react-datepicker__current-month {
                    color: #fff;
                    margin-bottom: 5px;
                }

                .react-datepicker__month-select, .react-datepicker__year-select {
                    background: #1B1B1D url('../../../assets/icons/arrow-down.png') no-repeat calc(100% + 3px) !important;
                    height: auto !important;
                    padding: 8px 16px !important;

                    &::-webkit-scrollbar {
                        width: 3px;
                    }
            
                    &::-webkit-scrollbar-track {
                        border-radius: 12px;
                    }
            
                    &::-webkit-scrollbar-thumb {
                        border-radius: 12px;
                        background-color: #464646;
                    }
                }

                .react-datepicker__day-names {
                    .react-datepicker__day-name {
                        color: #fff;
                    }
                }
            }

            .react-datepicker__month {
                .react-datepicker__week {
                    .react-datepicker__day {
                        color: #fff;

                        &:hover {
                            background: #FF8200;
                        }
                    }

                    .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected {
                        background: var(--colorLinearGradient);
                    }

                    .react-datepicker__day--disabled {
                        opacity: 0.38;

                        &:hover {
                            background: transparent;
                        }
                    }
                }
            }
        }
    }
}
