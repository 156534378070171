.collection-list-modal {
  min-width: 750px !important;

  .ant-modal-body {
    height: inherit;
  }

  .collections {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    &__item {
      // background: rgba(255, 255, 255, 0.1);
      background: #404040;
      padding: 1px;
      width: 100px;
      height: 100px;
      border-radius: 4px;

      .inner {
        height: 100%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: var(--dark);
        cursor: pointer;
        border-radius: 4px;

        img {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          margin-bottom: 8px;
          object-fit: cover;
        }

        p {
          font-size: var(--fontSmall);
          color: var(--gray);
          margin: 0;
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          text-align: center;
        }
      }

      &--selected {
        background: var(--colorLinearGradient);

        .inner p {
          font-weight: 700;
          color: var(--white);
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    min-width: unset !important;
    height: 80vh;

    .ant-modal-content {
      height: inherit;

      .modal-content {
        height: 100%;
        padding-bottom: 46px;
      }
    }

    .collections {
      height: inherit;
      overflow: auto;
    }
    .collections::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
  }

  @media screen and (max-width: 545px) {
    width: 300px !important;
  }
}
