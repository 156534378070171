.authentication-layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #000;
    position: relative;

    .top-toast-error {
        position: absolute;
        top: -48px;
        left: 0;
        z-index: 999;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 48px;
        background: var(--colorLightLinearGradient);
        transition: all ease 0.5s;
        font-size: 16px;
        font-weight: 600;
        color: var(--red);

        img {
            width: 24px;
            height: 24px;
            margin-right: 8px;
        }
    }

    .toast-visible {
        top: 0;
    }

    .authentication-logo {
        margin-bottom: 40px;
        transform: translateY(-80px);

        img {
            width: 210px;
            height: 40px;
        }
    }

    .authentication-box {
        transform: translateY(-80px);
        min-width: 440px;
        padding: 32px;
        background: #212127;
        border-radius: 8px;
        color: #fff;
        display: flex;
        flex-direction: column;

        .box-logo-mobile {
            display: none;
        }

        .box-title {
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;
        }

        .form-container {
            margin-top: 30px;

            .form-item {
                margin-top: 18px;

                &__label {
                    margin-bottom: 2px;
                    padding-left: 8px;
                    font-size: 12px;
                    font-weight: 600;
                }

                &__input {
                    display: flex;
                    align-items: center;
                    position: relative;
                    box-sizing: border-box;

                    input {
                        width: 100%;
                        height: 48px;
                        font-size: 14px;
                        background: transparent;
                        border: none;
                        padding: 12px 16px;
                        border: 1px solid #404040;
                        border-radius: 4px;
                        color: #fff;

                        &:focus {
                            outline: none;
                        }
                    }
                }

                .input-error {
                    input {
                        border-color: var(--red);
                    }
                }

                &__error {
                    color: var(--red);
                    margin-top: 2px;
                    padding-left: 8px;
                    font-size: 12px;
                    font-weight: 400;
                }
            }

            .btn-submit {
                width: 100%;
                height: 48px;
                border: none;
                border-radius: 50px;
                background: var(--colorLinearGradient);
                cursor: pointer;
                margin-top: 36px;
                color: #fff;
                font-size: 16px;
                font-weight: bold;
            }
            .btn-submit:disabled {
                opacity: 0.6;
                cursor: not-allowed;
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .authentication-layout {
        .authentication-logo {
            display: none;
        }

        .authentication-box {
            min-width: 100%;
            height: 100vh;
            border-radius: 4px;
            padding-left: 24px;
            padding-right: 24px;
            transform: translateY(0);

            .box-logo-mobile {
                display: block;
                margin-top: 32px;
                margin-bottom: 40px;
                align-self: center;

                img {
                    width: 210px;
                    height: 40px;
                }
            }
        }
    }
}
