@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

:root {
    --white: #ffffff;
    --dark: #121212;
    --gray: #acacac;
    --red: #ff3e3e;
    --lightRed: #ff3e3e33;
    --orange: #ff8200;
    --lightOrange: #ff820033;
    --darkOrange: #330C0C;
    --darkRed: #331A01;
    --green: #10b981;
    --neutral3: #404040;
    --neutral4: #1b1b1D;
    --fontXSmall: 12px;
    --fontSmall: 14px;
    --fontMed: 16px;
    --fontLarge: 18px;
    --fontXLarge: 24px;
    --colorLinearGradient: linear-gradient(
        99.51deg,
        var(--red) 8.87%,
        var(--orange) 94.14%
    );
    --colorLightLinearGradient: linear-gradient(
        99.51deg,
        var(--lightRed) 8.87%,
        var(--lightOrange) 94.14%
    );
    --colorOrangeLinearGradient: linear-gradient(
        99.51deg,
        var(--darkOrange) 8.87%,
        var(--darkRed) 94.14%
    );
}

* {
    font-family: 'Montserrat', sans-serif;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

::selection {
    background:#41b5ff8c !important; 
}

::-webkit-selection {
    background:#41b5ff8c !important; 
}
